import React from 'react';
import './ProjPage.css';
import Naavbarr from '../Naavbaar/Naavbaar.js';
import Footer from '../Footer/footer';
import Breadcrumbs from '../Breadcrumb/Breadcrumbs'; // Import the Breadcrumbs component
import { breadcrumbPaths } from '../Breadcrumb/breadcrumbPaths'; // Import paths from your paths file

const ProjPage = ({ projectName, projectDescription, additionalNotes, screenshots }) => {
  // Define the paths for the breadcrumbs for the current project page
  const paths = [
    breadcrumbPaths.home,
    breadcrumbPaths.projects,
    { name: projectName, link: '#' } // Current project name as the last breadcrumb (non-clickable)
  ];

  return (
    <>
      <Naavbarr />
      <Breadcrumbs paths={paths} /> {/* Add Breadcrumbs here */}
      <div className="proj-page">
        <h1>{projectName}</h1>
        <p>{projectDescription}</p>
        <h4>{additionalNotes}</h4>
        <div className="screenshots-section">
          <h2>Screenshots</h2>
          <div className="screenshots">
            {screenshots.map((src, index) => (
              <div key={index} className="screenshot-box">
                <img src={src} alt={`Screenshot ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ProjPage;
