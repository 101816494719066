import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Home from './Home';
import Pokevent from './Pokevent';
import TCracked from './TCracked';
import Projects from './Projects';
import APIProject from './APIProject';
import SBeeSolver from './SpellingBeeSolver';
import WebScraper from './WebScraper';
import Algorithms from './Algorithms';
import Database from './Database';
import Theory from './Theory';
import SysDesign from './SystemDesign';
import SoftwareDevI from './SD1';


function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/Pokevent" element={<Pokevent />}/>
        <Route path="/TriviaCracked" element={<TCracked/>}/>
        <Route path="/API-Project" element={<APIProject/>}/>
        <Route path="/Projects" element={<Projects/>}/>
        <Route path='/Spelling-Bee-Solver' element={<SBeeSolver/>}/>
        <Route path='/Pokevent-Web-Scraper' element={<WebScraper/>}/>
        <Route path='/Algorithms' element={<Algorithms/>}/>
        <Route path='/Database-Systems' element={<Database/>}/>
        <Route path='/Theory-Of-Programming-Languages' element={<Theory/>}/>
        <Route path='/System-Design' element={<SysDesign/>}/>
        <Route path='/Software-Dev-I' element={<SoftwareDevI/>}/>
      </Routes>
    </Router>
  );
}

export default App;
