
import ProjPage from "./components/Project Pages/ProjPage";
import ComingSoon from './proj-photos/ComingSoon.png';


const TCracked = () => {
  const projectDetails = {
    projectName: "Trivia Cracked",
    projectDescription: "Trivia Cracked is a program to answer trivia crack questions through Google's Gemini AI. Trivia Cracked is coded in Python using libraries such as PyAutoGUI, Pytesseract as well as Google's Gemini API. Screenshots of your Trivia Crack window are taken with PyAutoGUI. The text is then extracted using Pytesseract and put into a string. The string with the question and the options are then given to Gemini and the result is given back.",
    additionalNotes: "",
    screenshots: [
      ComingSoon,
      ComingSoon,
      ComingSoon
    ],
    repoLink: "https://github.com/EthanOndreicka/Trivia-Cracked",
  };

  return <ProjPage {...projectDetails} />;
};

export default TCracked;