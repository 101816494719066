
import ProjPage from "./components/Project Pages/ProjPage";

const APIProject = () => {
  const projectDetails = {
    projectName: "API Project",
    projectDescription: "Coming soon...",
    additionalNotes: "* Project is in active development *",
    screenshots: [
      
    ],
  };

  return <ProjPage {...projectDetails} />;
};

export default APIProject;