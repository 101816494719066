import React from 'react';
import ProjPage from './components/Project Pages/ProjPage';
import Pokevent1 from './proj-photos/Pokevent-1.png';

const Pokevent = () => {
  const projectDetails = {
    projectName: "Pokevent",
    projectDescription: "Pokevent is a Discord Bot that was created to send alerts about upcoming events happening in Pokemon Go. Pokevent uses Discord's API, Python, MySQL, and AWS. Pokevent currently runs off of a raspberry pi and has 24/7 uptime. The bot currently checks the database at certain points during the day to see if there is a scheduled event; if so, the bot will query the database with the event details, format the data into an embed, and then send the alert via a text channel in your discord server.",
    additionalNotes: "*Bot is still in active development*",
    screenshots: [
      Pokevent1,
      Pokevent1,
      Pokevent1
    ],
    repoLink: "https://github.com/EthanOndreicka/PokeventV1",
  };

  return <ProjPage {...projectDetails} />;
};

export default Pokevent;
