import ProjPage from "./components/Project Pages/ProjPage";
import ComingSoon from "./proj-photos/ComingSoon.png"

const SBeeSolver = () => {
  const projectDetails = {
    projectName: "Spelling Bee Solver",
    projectDescription: "Python file that gives you all the words possible for the daily NYT Spelling Bee. The program asks for all the letters and then will give back all of the words that can be made using the letters you give. All words will contain the <style>yellow letter.",
    additionalNotes: "",
    screenshots: [
      ComingSoon,
      ComingSoon,
      ComingSoon
    ],
    repoLink: "https://github.com/EthanOndreicka",
  };

  return <ProjPage {...projectDetails} />;
};

export default SBeeSolver;