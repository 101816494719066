// Projects.js
import React from 'react';
import ProjectSection from './ProjectSection';
import Naavbaar from './components/Naavbaar/Naavbaar';
import Breadcrumbs from './components/Breadcrumb/Breadcrumbs';
import Footer from './components/Footer/footer';
import './Projects.css'
import './components/Breadcrumb/breadcrumb.css'  
import { breadcrumbPaths } from './components/Breadcrumb/breadcrumbPaths';

// Dynamically import all images in the folder
const importAllImages = (r) => {
  let images = {};
  r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)));
  return images;
};

const images = importAllImages(require.context('./proj-thumbnails', false, /\.(png|jpe?g|svg)$/));

const Projects = () => {
  const personalProjects = [
    { name: 'Pokevent', image: images['pokevent.png'], projLink: '/Pokevent' },
    { name: 'Trivia Cracked', image: images['trivia-cracked.png'], projLink: '/TriviaCracked'},
    { name: 'Spelling Bee Solver', image: images['spelling-bee-solver.png'], projLink: '/Spelling-Bee-Solver' },
    { name: 'Pokevent Web Scraper', image: images['web-scraper.png'], projLink: '/Pokevent-Web-Scraper' },
    { name: 'API Project', image: images['API-Project.png'], projLink: '/API-Project' },
  ];

  const maristProjects = [
    { name: 'Algorithms', image: images['algorithms.png'], projLink: '/Algorithms'},
    { name: 'Database Systems', image: images['database-systems.png'], projLink: '/Database-Systems'},
    { name: 'Theory of Programming Languages', image: images['theory-of-prog.png'], projLink: '/Theory-Of-Programming-Languages'},
    { name: 'System Design', image: images['system-design.png'], projLink: '/System-Design'},
    { name: 'Software Dev I', image: images['software-dev-i.png'], projLink: '/Software-Dev-I'},
  ];

  const paths = [
    breadcrumbPaths.home,
    breadcrumbPaths.projects,
  ];

  return (
    <>
      <Naavbaar/>
      <Breadcrumbs paths={paths} />
      <h1 className='title'>All Projects</h1>
      <div>
        <ProjectSection title="Personal Projects" projects={personalProjects}/>
        <ProjectSection title="Marist Projects / Classwork" projects={maristProjects} />
      </div>
      <Footer/>
    </>
  );
};

export default Projects;
