import React from 'react'
import './works.css'
import { Link } from 'react-router-dom';
import Portfolio1 from '../../assets/portfolio-1.png'
import Portfolio2 from '../../assets/portfolio-2.png'
import Portfolio3 from '../../assets/portfolio-3.png'

const Works = () => {
  return (
    <section id='works'>
        <h2 className='worksTitle'>My Projects</h2>
        <span className='worksDescription'>I spend lots of time creating projects that I personally use on a daily basis. These projects have 
        helped me develop skills in many areas and has provided great practice.</span>
        <div className='worksImgs'>
          <Link to='/Pokevent'>
            <img src={Portfolio1} alt='' className='worksImg'/>
          </Link>
          <Link to='/TriviaCracked'>
            <img src={Portfolio2} alt='' className='worksImg'/>
          </Link>
          <Link to='/API-Project'>
            <img src={Portfolio3} alt='' className='worksImg'/>
          </Link>
        </div>

        <Link to='/Projects'>
          <button id='projButton'>View All Projects</button>
        </Link>

    </section>
  )
}

export default Works