import React from 'react';
import './intro.css';
import bg from '../../assets/image.png'

const Intro = () => {
  return (
    <section id='intro'>
        <div className='introContent'>
            <span className='hello'>Hello, </span>
            <span className='introText'>I'm <span className='introName'>Ethan</span>! <br />Software Developer</span>
            <p className='introParagraph'>I am a Software Developer with experience in multiple 
            <br/>programming languages and frameworks. </p>
        </div>
        <img src={bg} alt='' className='bg' />
    </section>
  )
}

export default Intro