// ProjectSection.js
import React from 'react';
import './ProjectSection.css'; // Optional if you want to style it in a separate file
import { Link } from 'react-router-dom';

const ProjectSection = ({ title, projects}) => {
  return (
    <div className="project-section">
      <h2>{title}</h2>
      <div className="projects-container">
        {projects.map((project, index) => (
        <div>
            <Link to={project.projLink}>
            <div key={index} className="project-card">
                    <img src={project.image} alt={project.name} className="project-image" />
            </div>
            </Link>
            <p className='proj-name'>{project.name}</p>
        </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectSection;
