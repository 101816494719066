// breadcrumbPaths.js
export const breadcrumbPaths = {
    home: { name: 'Home', link: '/' },
    projects: { name: 'Projects', link: '/Projects' },
    pokevent: { name: 'Pokevent', link: '/Pokevent' },
    triviaCracked: { name: 'Trivia Cracked', link: '/TriviaCracked' },
    apiProject: { name: 'API Project', link: '/API-Project' },
    spellingBeeSolver: { name: 'Spelling Bee Solver', link: '/Spelling-Bee-Solver' },
    webScraper: { name: 'Pokevent Web Scraper', link: '/Pokevent-Web-Scraper' },
  };
  