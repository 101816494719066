import ProjPage from "./components/Project Pages/ProjPage";
import ComingSoon from "./proj-photos/ComingSoon.png"

const Database = () => {
  const projectDetails = {
    projectName: "Database Class",
    projectDescription: ".",
    additionalNotes: "",
    screenshots: [
      ComingSoon,
      ComingSoon,
      ComingSoon
    ],
    repoLink: "https://github.com/EthanOndreicka",
  };

  return <ProjPage {...projectDetails} />;
};

export default Database;