import ProjPage from "./components/Project Pages/ProjPage";
import ComingSoon from "./proj-photos/ComingSoon.png"

const WebScaper = () => {
  const projectDetails = {
    projectName: "Pokevent Web Scraper",
    projectDescription: "This web scraper is used to get info from LeekDuck and put it into my database. It works by entering the webpage link and then all relevant information will be scraped. Once it is scraped, it will show an example embed to see if all information is correct. Once it looks good, you can press submit to send the info to the database. This was built with Python, ElectronJS, JavaScript, BeautifulSoup, and PostgreSQL",
    additionalNotes: "",
    screenshots: [
      ComingSoon,
      ComingSoon,
      ComingSoon
    ],
    repoLink: "https://github.com/EthanOndreicka",
  };

  return <ProjPage {...projectDetails} />;
};

export default WebScaper;